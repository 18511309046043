import * as React from "react"
import { Box, Center, Spinner } from "@chakra-ui/react"
import { useRouter } from "next/router"

import { useMe } from "lib/hooks/useMe"

import { AuthNav } from "./AuthNav"
import { Limiter } from "./Limiter"
import { Footer } from "./Footer"
import { REDIRECT_PATH } from "lib/config"

interface Props {
  children: React.ReactNode
}

export function AuthLayout(props: Props) {
  return (
    <Box>
      <AuthNav />
      <Limiter pt="80px">{props.children}</Limiter>
      <Footer />
    </Box>
  )
}

export function AuthCheck(props: Props) {
  const router = useRouter()
  const { me, loading } = useMe()
  const redirect = router.query[REDIRECT_PATH] as string | undefined

  React.useEffect(() => {
    if (loading) return
    if (me) {
      router.replace(redirect || "/")
    }
  }, [loading, me, router, redirect])

  if (loading || me) {
    return (
      <Center minH="100vh">
        <Spinner />
      </Center>
    )
  }

  return <>{props.children}</>
}
