import * as React from "react"
import type { LinkProps } from "@chakra-ui/react"
import { Image } from "@chakra-ui/react"
import { Box, Fade, HStack, Link, useColorModeValue } from "@chakra-ui/react"
import NextLink from "next/link"
import { useRouter } from "next/router"

import { useMe } from "lib/hooks/useMe"

import { Limiter } from "./Limiter"
import { LinkButton } from "./LinkButton"

export function AuthNav() {
  const { me, loading } = useMe()

  return (
    <Box
      w="100%"
      pos="fixed"
      top={0}
      left={0}
      borderBottom="1px solid"
      borderColor={useColorModeValue("gray.100", "gray.700")}
      zIndex={500}
    >
      <Limiter
        display="flex"
        transition="200ms all"
        py={{ base: 4, md: 3 }}
        bg={useColorModeValue("white", "gray.800")}
        justifyContent="space-between"
        alignItems="center"
        w="100%"
      >
        {/* Left link list */}
        <HStack spacing={6} color="gray.400" fontSize="sm">
          <HomeLink
            href="/"
            color={useColorModeValue("pink.600", "pink.400")}
            pl={0}
            textTransform="uppercase"
            fontWeight="bold"
          >
            <Image
              alt="greymen logo"
              src={useColorModeValue("/logo.png", "/logo-white.png")}
              h="40px"
              alignSelf={{ base: "flex-start", md: "flex-end" }}
            />
          </HomeLink>
        </HStack>

        {/* Right link list */}

        {!me && !loading && (
          <Fade in>
            <HStack spacing={4} display={{ base: "none", md: "flex" }}>
              <LinkButton href="/login" variant="ghost">
                Login
              </LinkButton>
            </HStack>
          </Fade>
        )}
      </Limiter>
    </Box>
  )
}

interface HomeLinkProps extends LinkProps {
  href: string
}

function HomeLink({ href, ...props }: HomeLinkProps) {
  const { asPath } = useRouter()
  const isActive = asPath === href

  return (
    <Link
      as={NextLink}
      px={4}
      href={href}
      py={2}
      textDecor="none !important"
      _hover={{ color: isActive ? "pink.600" : "pink.500" }}
      color={isActive ? "pink.600" : "gray.500"}
      {...props}
    >
      {props.children}
    </Link>
  )
}
